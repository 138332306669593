import React from "react";
import Home3 from "./home-3";
// import Section from "../layout/Section";

const IndexPage = () => {
  return (
      <Home3 />
  );
};
export default IndexPage;
